/*eslint-disable */
import type { AssetDetails } from '../menu-modal/MenuModal/portal-asset-context';

class LegacyPortalApi {
  // private portalAxios: AxiosInstance = createPortalAxios();
  // constructor(private api: AxiosInstance) {}

  GetAssetName(id: number): Promise<string> {
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Alerts/GetAssetName',
        cache: false,
        async: false,
        data: {
          AssetID: id
        },
        success: function (oData) {
          resolve(oData.data);
        }
      });
    });
  }
  getSiteIntIdByGuid(uuid: string): Promise<number> {
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Site/RmxId',
        cache: false,
        async: false,
        data: { uuid: uuid },
        dataType: 'json',
        success: function (oData) {
          resolve(oData);
        }
      });
    });
  }

  getInstallationName(companyName: string, siteName: string): Promise<string> {
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Home/GetInstallationName',
        cache: false,
        async: false,
        data: {
          CompanyName: companyName,
          SiteName: siteName
        },
        success: function (oData) {
          resolve(oData.data);
        }
      });
    });
  }

  getAlertDetailsAsync(id: string, type: string): Promise<any> {
    return new Promise((resolve) => {
      $.ajax({
        url: type === 'Site' ? '/Alerts/GetSiteAlertsMouseHover' : '/Alerts/GetCompanyAlertsMouseHover',
        method: 'get',
        data: type === 'Site' ? { SiteID: id } : { CompanyID: id },
        success: function (oData: { havedata: boolean; data: string }) {
          if (oData.havedata) {
            resolve(oData);
          }
        },
        error: function (result) {}
      });
    });
  }

  getCompanyOrSiteNameAsync(id: string, type: string): Promise<string> {
    // console.log(`id: ${id}, type: ${type}`)
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: type === 'Site' ? '/Alerts/GetSiteName' : '/Alerts/GetCompanyName',
        cache: false,
        async: false,
        data: type === 'Site' ? { SiteID: id } : { CompanyID: id },
        success: function (oData: { data: string }) {
          resolve(oData.data);
        }
      });
    });
  }

  getAssetDetails(id: number): Promise<AssetDetails> {
    // console.log(`id: ${id}, type: ${type}`)
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Alerts/GetAssetDetails',
        cache: false,
        async: true,
        data: { assetId: id },
        success: function (oData: AssetDetails) {
          resolve(oData);
        }
      });
    });
  }
}

export function getLegacyPortalApi() {
  return new LegacyPortalApi();
}
