import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { CanceledError } from 'axios';

interface DebounceRequest<T>{
  fn: (value: T, signal: AbortSignal) => Promise<unknown>;
  debounceTime: number;
}

export function useDebounceRequest<T>(props: DebounceRequest<T>) {
  const timeoutIdRef = useRef<number | null>(null);
  const abortRef = useRef<AbortController | null>(null);
  const {
    mutateAsync: performMutation,
    status,
    reset
  } = useMutation({
    mutationFn: async ({value, signal}: {value: T; signal: AbortSignal}) => {
      try {
        await props.fn(value, signal);
        abortRef.current = null; // prevent abort controller abort from being called when it is no longer needed.
        return true;
      } catch (error) {
        if (error instanceof CanceledError) {
          console.log('Request was cancelled'); // do not propagate this error as it is intentional and should not cause errors to be reported
          return false;
        }
        throw error;
      }
    }
  });

  const handleDebounceRequest = async (value: T) => {
    if (abortRef.current) {
      abortRef.current.abort();
      abortRef.current = null; /// js is single threaded so this is safe to set it to null since it will not get assigned between these two lines.
    }
    reset();
    // Clear existing timeout every time the user types
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
    // Set a new timeout when user stops typing
    timeoutIdRef.current = window.setTimeout(async () => {
      abortRef.current = new AbortController();
      await performMutation({value, signal: abortRef.current!.signal});
    }, props.debounceTime); // Adjust debounce time as needed
  };

  const statusInfo = getStatusInformation(status);
  return { handleDebounceRequest, statusInfo };
}

function getStatusInformation(status: 'pending' | 'success' | 'error' | string) {
  switch (status) {
    case 'pending':
      return { color: 'white', text: 'Saving...' };
    case 'success':
      return { color: 'green', text: 'Saved' };
    case 'error':
      return { color: 'red', text: 'Error saving. Please check your internet connection and type again.' };
    default:
      return undefined;
  }
}
