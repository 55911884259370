import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { RmxServiceComponent } from './RmxServiceComponent';
import { useModal } from '../../hooks/useModal';
import { useState } from 'react';

export interface ServiceFilterParams {
  assetId?: number;
  siteId?: number;
  companyId?: number;
}
export let openServiceModal = (f: ServiceFilterParams) => {};
window.openServiceModal = (f: ServiceFilterParams) => openServiceModal(f);

export function RmxServiceComponentModal() {
  const s = useModal<ServiceFilterParams>();
  const [serviceFilterParams, setServiceFilterParams] = useState<ServiceFilterParams>({});

  openServiceModal = async (f: ServiceFilterParams) => {
    setServiceFilterParams(f);
    await s.open(f);
  };

  return (
    <GenericModal state={s} label={'Service'} fullScreen={true} showClose={true}>
      <RmxServiceComponent params={serviceFilterParams} />
    </GenericModal>
  );
}
