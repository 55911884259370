import { Card } from 'react-bootstrap';
import React from 'react';
import { ReactTable, type SimpleColumnDef } from '../../utils/ReactTable/ReactTable';
import type { Part, PartPickerRequest } from '../Service.types';
import { type QueryObserverResult, useMutation } from '@tanstack/react-query';
import { useRmxServiceApi } from '../api';
import { useRmxServiceContext } from '../RmxServiceComponent/RmxServiceContext';
import { LoadingButton } from '../../../components/util/widgets/LoadingButton/LoadingButton';

interface Props {
  part: Part;
  setOpen: (open: boolean) => void;
  refetch: () => Promise<QueryObserverResult<Part[], Error>>;
}
export function PickPart({ part, setOpen, refetch }: Props) {
  const columns = [
    { header: 'Column1', accessorKey: 'Column1' },
    { header: 'Column2', accessorKey: 'Column2' }
  ] satisfies SimpleColumnDef[];

  const details = part && [
    { Column1: 'CCN', Column2: part.ccn },
    { Column1: 'Part Name', Column2: part.partName },
    { Column1: 'Ordered Date', Column2: part.orderedOn?.toDateString() },
    { Column1: 'QTY', Column2: part.totalQuantity },
    { Column1: 'Pr Destination', Column2: part.prDestination },
    { Column1: 'Activity Id', Column2: part.activityCrmId },
    { Column1: 'Vendor', Column2: part.vendorName },
    { Column1: 'Expected On', Column2: part.expectedOn?.toLocaleDateString() }
  ];
  const { refetchServiceTable } = useRmxServiceContext();

  const api = useRmxServiceApi();

  const { mutateAsync: pickPart, isPending } = useMutation({
    mutationFn: async () => {
      const request = {
        partIds: part.ids,
        quantity: part.totalQuantity
      } satisfies PartPickerRequest;
      await api.pickPart(request);
      await refetchServiceTable();
      await refetch();
      setOpen(false);
    }
  });

  const canPick = part.status === 'Staged' || part.canPick;
  return (
    <>
      <Card>
        <ReactTable striped={false} bordered={false} columns={columns} includeHeaders={false} data={details} />
      </Card>

      {!canPick && (
        <div style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold' }}>
          {part.status === 'Picked' ? 'Part has already been picked.' : 'Part is not ready to be picked.'}
        </div>
      )}

      <LoadingButton
        variant='secondary'
        disabled={!canPick}
        loading={isPending}
        onClick={async () => {
          await pickPart();
        }}
        style={{ width: '100%' }}
        label='Pick Part'
      />
    </>
  );
}
