import { type MenuConfig } from './MenuModal';
import { type ActionConfig } from '../ActionModal/ActionModal';
import { openComponentLifeModal } from '../../component-life/ComponentLifeComponent/ComponentLifeComponent';
import { getAssetUuidFromAssetId } from 'src/portal/portal-api';
import { openServiceModal } from '../../rmx-service/RmxServiceComponent/RmxServiceComponentModal';
import { openBypassModal } from '../../utils/BypassModal/BypassModal';
import { getLegacyPortalApi } from '../../legacy-portal-api/legacy-api';
import { getPortalServiceApi } from '../../rmx-service/api/portal-api';
/**
 * This File contains the indicator menu modal for assets
 * initialized in the asset-page.js file
 */

// data structure for the asset details coming from portal
export type AssetDetails = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AssetModel: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AssetSerial: string;
};

declare global {
  interface Window {
    initializeAssetModalMenu: () => void;
    getCompanyOrSiteNameAsync: (id: string, type: string) => Promise<string>;
    openViewRequestsDialog: (id: string, type: string) => Promise<void>;
    openSubmitRequestDialogFor: (id: string, type: string) => Promise<void>;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    AddByPass: (callback?: (bypassReason: string) => void) => void;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ClearByPass: () => void;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    IsComponentLifeEnabled: boolean;
  }
}

// @ts-ignore
window.initializeAssetModalMenu = function () {
  const url = new URL(window.location.href);
  const companyId = window.PageParams.CompanyId;
  const siteId = window.PageParams.SiteId;

  // function getAssetId() {
  //   return parseInt((document.getElementById('AssetID') as HTMLInputElement).value);
  // }

  // @ts-ignore
  window.OpenIndicatorMenuModal = async function (assetId: number, type: string, assetName: string) {
    // const assetId = getAssetId();
    const legacyApi = getLegacyPortalApi();
    const rmxApi = getPortalServiceApi();
    const syncedUser = await rmxApi.syncedUser();
    const assetUuid = await getAssetUuidFromAssetId(assetId);
    const reportsUrl = `${window.origin}?p=SystemAssessment&cId=${companyId}&sId=${siteId}`;
    const installationName = window.PageParams.InstallationName;
    const parentAssetUuid = window.PageParams.ParentAssetUuid;
    const siteName = await legacyApi.getCompanyOrSiteNameAsync(siteId!, 'Site');
    const assetDetails = await legacyApi.getAssetDetails(assetId);

    const menuConfig: MenuConfig<any> = {
      flag: () => {},
      // action: () => {}, // empty action handler used to show the action button
      alerts: () => (window.location.href = `${url.origin}?p=Alerts&c=${companyId}&s=${siteId}&i=${installationName}&a=${encodeURIComponent(assetName)}`),
      // history: () => {}, // coming soon
      documents: () => {
        // when the asset is a module the parentAssetUuid variable is filled out.
        // we want to go to the uploads page of the parent asset for the module.
        // for embedded assets the parentAssetUuid should be null and we want to go to the uploads page of the asset itself.
        window.location.href = `${window.origin}/?p=Uploads&pid=${parentAssetUuid ?? assetUuid}`;
      },
      graphing: () => {
        window.location.href = `${window.origin}/?p=ChartingPrefill&aId=${assetUuid}`;
      },
      service: () => {
        openServiceModal({ assetId });
      },
      reports: () => {
        window.location.href = reportsUrl;
      },
      componentLife: () => {
        openComponentLifeModal();
      },
      getLabel: () => assetName,
      // view_requests: () => {
      //   // view requests
      //   window.openViewRequestsDialog(id, type);
      // },
      // request: () => {
      //   window.openSubmitRequestDialogFor(id, type);
      // },
      bypass: async () => {
        openBypassModal({
          id: assetId,
          siteName: siteName!,
          type: 'Asset',
          assetModel: assetDetails.AssetModel,
          assetSerial: assetDetails.AssetSerial,
          syncedUser
        });
        // window.AddByPass((bypassReason: string) => {
        //   console.log('bypass: ', bypassReason);
        // });
        // const api = getPortalServiceApi();
        // await api.createFlag({
        //   userGroup: 'Support',
        //   reason: `${installationName} - ${assetName} - Bypassed`
        // });
      }
    };
    if (!window.IsComponentLifeEnabled) {
      delete menuConfig.componentLife;
    }
    if (!window.User.IsRmxServiceEnabled) {
      delete menuConfig.service;
      delete menuConfig.flag;
    }

    const actionConfig: ActionConfig<any> = {
      // view_requests: () => {
      //   // view requests
      //   window.openViewRequestsDialog(id, type);
      // },
      // submit_requests: () => {
      //   // submit request
      //   window.openSubmitRequestDialogFor(id, type);
      // },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      bypass_add: () => {
        const c = window.AddByPass();
        console.log('bypass', c);
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      bypass_clear: () => {
        window.ClearByPass();
      }
      // change: () => {}, // coming soon
      // snooze: () => {}, // coming soon

      // edit: () => {openNotesDialog(id, type)},
    };

    window.OpenMenuModal(assetName, menuConfig, actionConfig); // passing in the siteName into the resource to be able to use it with creating flags
  };
};
