import { GrowingTextArea } from '../GrowingTextArea';
import React, { useState } from 'react';
import DarkSelect from '../../shared-compat/utils/DarkSelect/DarkSelect';
import { GenericModal } from '../GenericModal/GenericModal';
import { useModal } from '../../hooks/useModal';
import { FormRow } from '../../rmx-service/Flags/RmxViewFlag';
import { LoadingButton } from '../widgets/LoadingButton/LoadingButton';
import { type OrgLevel } from '../../../api/userDashboardApi';
import { createPortalAxios } from '../ajax';
import { getPortalServiceApi } from '../../rmx-service/api/portal-api';
import { handleAxiosError } from '../../../common/util/http';

interface BypassModalProps {
  id: number | string;
  siteName: string;
  assetModel?: string;
  assetSerial?: string;
  type: OrgLevel;
  syncedUser: boolean;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    openBypassModal: (f: BypassModalProps) => void;
  }
}
export let openBypassModal = (f: BypassModalProps) => {};
window.openBypassModal = (f: BypassModalProps) => openBypassModal(f);

export function BypassModal() {
  const api = getPortalServiceApi();
  const [reason, setReason] = useState<string>('');
  const [action, setAction] = useState<string>('Ignore');

  const [loading, setLoading] = useState<boolean>(false);
  const state = useModal<BypassModalProps>();

  openBypassModal = async (f: BypassModalProps) => {
    await state.open(f);
  };

  return (
    <>
      <GenericModal label={'Bypass'} state={state} showClose={true}>
        <FormRow label={'Reason'} content={<GrowingTextArea readOnly={false} value={reason} setValue={setReason} />} />
        {state.param?.syncedUser === true && (
          <FormRow label={'Action'} content={<DarkSelect onChange={(e) => setAction(e)} options={['Ignore', 'Create Flag']} value={action} />} />
        )}
        <LoadingButton
          style={{ width: '100%' }}
          label={'Submit'}
          disabled={false}
          variant='secondary'
          onClick={async () => {
            try {
              setLoading(true);
              const requests: Promise<unknown>[] = [createBypass({ id: state.param!.id, otherReason: reason, bypassAction: action }, state.param!.type)];
              if (action === 'Create Flag') {
                requests.push(
                  api.createFlag({
                    userGroup: 'Support',
                    reason:
                      state.param!.type === 'Asset'
                        ? `Site: ${state.param?.siteName};  Asset Model: ${state.param?.assetModel}; Asset Serial: ${state.param?.assetSerial}; Reason: ${reason}`
                        : `Site: ${state.param?.siteName}; Reason: ${reason}`
                  })
                );
              }
              await Promise.all(requests);
              state.close();
              window.location.reload();
            } catch (e) {
              alert(`Error Bypassing: ${handleAxiosError(e)}`);
            } finally {
              setLoading(false);
            }
          }}
          loading={loading}
        />
      </GenericModal>
    </>
  );
}

interface CreateBypassRequest {
  id: number | string;
  otherReason: string;
  bypassAction: string;

  siteName?: string;
  assetModel?: string;
  assetSerial?: string;
}

export function createBypass(request: CreateBypassRequest, type: OrgLevel) {
  const api = createPortalAxios();
  if (type === 'Asset') {
    return api.get('/Alerts/ManageAsset', {
      params: {
        AssetID: request.id,
        IsOther: 1,
        IsAutomatic: 1,
        OtherReason: request.otherReason,
        BypassAction: request.bypassAction
      }
    });
  } else if (type === 'Site') {
    return api.get('/Alerts/ManageSite', {
      params: {
        SiteId: request.id,
        IsOther: 1,
        IsAutomatic: 1,
        OtherReason: request.otherReason,
        BypassAction: request.bypassAction
      }
    });
  } else {
    reportError('Invalid type for bypass request');
    return Promise.reject('Invalid type for bypass request');
  }
}
