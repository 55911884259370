import React from 'react';
import { VerticalInputRow } from '../../components/util/form-components/VerticalInputRow';
import { GrowingTextArea } from './GrowingTextArea';
import { Form } from 'react-bootstrap';

interface Props {
  value: string;
  setValue: (value: string) => void;
  readonly: boolean;
  statusInfo: { color: string; text: string } | undefined;
  placeholder?: string;
  footer?: React.ReactNode;
  maxLength?: number;
  label: string;
}
export function AutoSaveTextArea(props: Props) {
  return (
    <VerticalInputRow label={props.label}>
      <GrowingTextArea maxLength={props.maxLength} placeholder={props.placeholder} readOnly={props.readonly} value={props.value} setValue={props.setValue} />
      {props.footer}
      {props.statusInfo && <Form.Text style={{ color: props.statusInfo?.color, height: '1.5em', fontSize: '0.9rem' }}>{props.statusInfo?.text}</Form.Text>}
    </VerticalInputRow>
  );
}
