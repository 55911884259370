// TODO The issues and the recommendations columns should be calculated in the backend and placed in the DTO
// The externalInt ID relationship to the asset ID should also be grabbed from the backend.
// We are missing loaded hours and the last tan value.
import { type Sample } from '../Service.types';
import { createServerColumnHelper } from '../../shared-compat/DataTable/hooks';

const helper = createServerColumnHelper<Sample>();
const ch = helper.accessor;
const display = helper.display;
export const sampleColumnsDef = () => [
  ch('action', 'string', {
    header: 'Action',
    enableSorting: true,
    sortDescFirst: true,
    cell: (info) => {
      return info.getValue();
    },
    footer: (props) => props.column.id
  }),
  ch('issues', 'string', {
    header: 'Issues',
    enableColumnFilter: true,
    meta: {
      isVisible: false
    },
    cell: (info) => {
      return info.getValue();
    },
    footer: (props) => props.column.id
  }),
  ch('siteName', 'string', {
    header: 'Customer',
    enableColumnFilter: true,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id
  }),
  display({
    header: 'Asset',
    enableColumnFilter: true,
    cell: (info) => `${info.row.original.assetModel} - ${info.row.original.assetSerial}`,
    footer: (props) => props.column.id
  }),
  ch('sampleDate', 'date', {
    header: 'Sample Date',
    enableColumnFilter: true,
    cell: (info) => {
      return info.row.original.sampleDate.toLocaleDateString();
      // return date.toLocaleDateString();
    },
    footer: (props) => props.column.id
  }),
  ch('externalId', 'number', {
    header: 'External Crm Id',
    meta: {
      isVisible: false
    },
    enableColumnFilter: false,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id
  }),
  ch('loadHours', 'number', {
    header: 'Load Hours',
    meta: {
      isVisible: false
    },
    enableColumnFilter: false
  }),
  ch('bearingMetals', 'number', {
    cell: (info) => info.getValue(),
    meta: {
      isVisible: false
    },
    enableColumnFilter: false,
    header: 'Bearing Metals',
    footer: (props) => props.column.id
  }),
  ch('coolerMetals', 'number', {
    header: 'Cooler Metals',
    meta: {
      isVisible: false
    },
    enableColumnFilter: false,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id
  }),
  ch('oxidation', 'number', {
    meta: {
      isVisible: false
    },
    enableColumnFilter: false,
    header: () => 'Oxidation',
    footer: (props) => props.column.id,
    cell: (info) => info.getValue()
  }),
  ch('filter', 'number', {
    meta: {
      isVisible: false
    },
    enableColumnFilter: false,
    header: 'Filter',
    footer: (props) => props.column.id
  }),
  ch('ph', 'number', {
    header: 'PH',
    meta: {
      isVisible: false
    },
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    cell: (info) => info.getValue()
  }),
  ch('viscosityDeviations', 'number', {
    header: 'Viscosity Deviation',
    meta: {
      isVisible: false
    },

    enableColumnFilter: false,
    footer: (props) => props.column.id
  }),

  ch('viscosity', 'number', {
    header: 'Viscosity',
    meta: {
      isVisible: false
    },
    enableColumnFilter: false,
    footer: (props) => props.column.id
  })
];
