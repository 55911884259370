import { importOrShowErrorAndReturnNull } from '../../util/import-utils';

export async function getChart(chartsId: string) {
  const Highcharts = await importOrShowErrorAndReturnNull(
    async () => (await import('./lazyHighcharts')).Highcharts,
    'Failed to load chart. Please check your internet connection and try again'
  );
  if (Highcharts === null) {
    return;
  }
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return Highcharts.charts.find((c) => c!.renderTo.id === chartsId);
}
// @ts-ignore
window.getChart = getChart;
