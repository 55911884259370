
export async function importOrShowErrorAndReturnNull<TImportReturn>(importer: () => Promise<TImportReturn>, alertMessage: string): Promise<TImportReturn|null> {
  try {
    return await importer();
  } catch (e) {
    alert(alertMessage);
    reportError(e);
    return null;
  }
}
