import { addHours, addMinutes } from 'date-fns';
import React from 'react';
import { parseTimeSpan, type TimeSpan } from '../utils/timespan';

export interface ActivityResponse {
  id: string;
  activityCrmId: string;
  uuid: string;
  workOrderId: number;
  customerId: number;
  customerName: string;
  customerNotes: string | null;
  startDate: string;
  assignedToUserId: number;
  assignedToName: string;
  assignedToEmail: string;
  description: string;
  activityType: string;
  availability: string;
  onSiteTime: string;
  estimatedDuration: TimeSpan;
  assetId: number;
  installationId: number;
  siteId: number;
  assetName: string;
  customerAddress: string;
  comments: string;
  location: string | null;
  status: 'In Progress' | 'Voided' | 'Archived' | 'Completed';
  agreementId: number | null;
  agreementType: string | null;
  createdAt: string;
  closedOn: string;
  contactId: number;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  assetManufacturer: string;
  assetGroup: string;
  assetLocation: string | null;
  partStatus: string;
  siteName: string;
  assetModel: string;
  assetSerial: string;
  assetCrmId: string;
  workCompletedDescription: string | null;
  privateNotes: string | null;
  debriefedWith: string | null;
  debriefType: string | null;
  confirmAssetId: number | null;
  assetHours: number | null;
  unknownAssetHourReasons: string | null;
  contactConfirmationStatus: string | null;
  unknownAssetHoursReason: string | null;
  confirmedContact: boolean;
  contactConfirmedNoResponse: boolean;
  additionalReviewRequired: boolean;
  assetCrmType: string | null;
}

export interface ActivityContactInteraction {
  id: number;
  name: string;
  type: string;
  communicationMethod?: string;
  contact: ContactInfo;
  contactId: number;
}

export enum ActivityStatus {
  InProgress = 'In Progress',
  Voided = 'Voided',
  Archived = 'Archived',
  Completed = 'Completed'
}

export function isActivityInProgress(a: Activity) {
  return a.status === 'In Progress';
}

export function getActivityEndDateTime(activity: Activity): Date {
  const startTime = activity.onSiteTime;
  const estimatedDurationDate = parseTimeSpan(activity.estimatedDuration);
  const estimatedDurationMinutes = estimatedDurationDate.getMinutes();
  const estimatedDurationHours = estimatedDurationDate.getHours();
  let endTime = addHours(startTime, estimatedDurationHours);
  endTime = addMinutes(endTime, estimatedDurationMinutes);
  return endTime;
}

export function formatActivityStart(activity: Activity) {
  const startTime = activity.onSiteTime;
  const endTime = getActivityEndDateTime(activity);
  return (
    <div>
      {activity.startDate.toDateString()} <br /> {startTime.toLocaleTimeString()} - {endTime.toLocaleTimeString()}
    </div>
  );
}

export type Activity = Omit<ActivityResponse, 'startDate' | 'closedOn' | 'createdAt' | 'onSiteTime'> & {
  startDate: Date;
  onSiteTime: Date;
  // ClosedOn: Date | null;
  createdAt: Date;
};

export interface PartResponse {
  ids: number[];
  ccn: string;
  partName: string; // Description
  activityId: number;
  activityCrmId: string;
  activityUuid: string;
  partDescription: string;
  assetId: number;
  installationId: number;
  siteId: number;
  assetName: string;
  usedOn: string | null; // Date in string format
  totalQuantity: number;
  usedQuantity: number;
  orderedOn: string | null;
  expectedOn: string;
  partNumber: number; // PartRequestId
  prDestination: string | null;
  purchaseOrderNumber: number;
  poDestination: string | null;
  pickedByUserId: string;
  pickedOn: string;
  pickedQuantity: number;
  currentLocation: string;
  canPick: boolean;
  status: 'Ordered' | 'Picked' | 'Received' | 'Staged' | 'Transit';
  trackingNumber: string;
  vendorName: string;
  activityStatus: string;
  partStatus: string;
  surplusReason: string;
}

export type Part = Omit<PartResponse, 'expectedOn' | 'orderedOn' | 'usedOn'> & {
  expectedOn: Date | null;
  orderedOn: Date | null;
  usedOn: Date | null;
  // pickedOn: Date | null;
};

export type ItemInventoryResponse = {
  id: number;
  ccn: string;
  partName: string;
  price: number;
  altPart?: string;
  lastUsedOn: string | null;
  quantity: number;
};

export type ItemInventory = Omit<ItemInventoryResponse, 'lastUsedOn'> & {
  lastUsedOn: Date | null;
};

export type MyPartsResponse = {
  id: number;
  ccn: string;
  partName: string;
  quantity: number;
  activityId?: number;
  customerName?: string;
  price?: number;
};

enum InvoiceType {
  Open = 'Open',
  PastDue = 'Past Due',
  Paid = 'Paid'
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface InvoiceResponse {
  Status: InvoiceType;
  InvoiceId: number;
  WorkOrderId: number;
  AssetId: number;
  SiteId: number;
  InstallationId: number;
  AssetName: string;
  InvoiceType: string;
  Customer: string;
  Description: string | null;
  Value: number;
  CreatedOn: string; // Date in string format
  DueOn: string; // Date in string format
}

export type Invoice = Omit<InvoiceResponse, 'CreatedOn' | 'DueOn'> & {
  CreatedOn: Date;
  DueOn: Date;
};
/* eslint-enable @typescript-eslint/naming-convention */
export interface SampleResponse {
  action: string;
  assetId: number;
  issues: string | null;
  siteId: number;
  externalId: number;
  loadHours: number;
  bearingMetals: number;
  coolerMetals: number;
  oxidation: number;
  filter: number;
  ph: number;
  viscosityDeviations: number;
  viscosity: number;
  recommendations: string;
  customer: string;
  assetName: string;
  siteName: string;
  sampleDate: string; // Date in string format
  assetModel: string;
  assetSerial: string;
}

export type Sample = Omit<SampleResponse, 'sampleDate'> & {
  sampleDate: Date;
};

export enum EUserGroups {
  Support = 'Support',
  Orders = 'Orders',
  Accounting = 'Accounting',
  Sales = 'Sales'
}

export interface FlagResponse {
  id: number;
  activityCrmId: string;
  status: string;
  activityId: string;
  createdAt: string;
  createdByUserName: string;
  createdById: number;
  reason: string;
  respondedAt: string | null;
  respondedByUserName: string | null;
  respondedById: number | null;

  response: string | null;
  clearedAt: string | null;
  claimedById: number | null;
  claimedAt: string | null;
  claimedByUserName: string | null;
  flagRecipientGroup: EUserGroups;
  siteName: string | null;
}

export interface FlagCreateRequest {
  activityId?: string;
  customerId?: number;
  siteName?: string;
  userGroup: string;
  reason: string;
}
export interface FlagClearRequest {
  flagId: number;
  response: string;
}
export type Flag = Omit<FlagResponse, 'createdAt' | 'respondedAt' | 'clearedAt' | 'claimedAt'> & {
  createdAt: Date;
  respondedAt: Date | null;
  clearedAt: Date | null;
  claimedAt: Date | null;
};

export interface ActivityExpenseResponse {
  id: number;
  activityId: string;
  description: string;
  amount: number;
  saleTaxAmount: number;
  vendorName: string;
  paymentType: string;
  createdAt: string;
}

export type ActivityExpense = Omit<ActivityExpenseResponse, 'createdAt' | 'updatedAt'> & {
  createdAt: Date | null;
};

export interface QuoteResponse {
  qId: string;
  activityCrmId: string;
  quoteCrmId: string | null;
  assetName: string;
  status: string;
  title: string;
  type: string;
  expiresAt: string;
  customerName: string | null;
  activityId: string;
  assetId: number;
  estimatedDuration: TimeSpan;
  includedParts?: QuotePart[];
  numberOfTechs: number;
  description: string;
  privateNotes: string;
  createdAt: string;
  createdByName: string | null;
  quoteTotal: number;
  uploadFileName?: string | null;
  isSubmitted: boolean;
}

export type Quote = Omit<QuoteResponse, 'createdAt' | 'expiresAt'> & {
  createdAt: Date;
  expiresAt: Date;
};

export interface QuotePart {
  part: ItemInventory;
  selectedQuantity: number;
}

export interface GetQuoteResponse {
  assetId: number;
  description: string;
  estimatedDuration: TimeSpan;
  includedParts: QuotePart[];
  numberOfTechs: number;
  title: string;
  upload: object;
}

export interface CreateQuoteRequest {
  id?: string | null;
  title: string;
  activityCrmId: string;
  assetId: number;
  numberOfTechs: number;
  estimatedDuration: TimeSpan;
  description?: string;
  privateNotes: string;
  includedParts: QuotePart[];
}

export interface CreateActivityExpenseRequest {
  id?: string;
  description: string;
  amount: number;
  saleTaxAmount: number;
  vendorName: string;
  paymentType: string;
  expenseTypeId?: number | null;
}

export interface GetCustomerAndSiteAssetsRequest {
  customerId: number;
  siteId: number;
}

export interface GetAssignedToResponse {
  id: number;
  name: string;
}

export interface ActivityTimeResponse {
  activityId: string;
  entryDate: string; // Date in string format
  startTime: string; // Date in string format
  endTime: string; // Date in string format
  type: string;
  totalTime: string; // Date in string format
  userId: string;
}

export interface UpdateActivityDebriefRequest {
  activityId: string;
  scope: string;
  usedParts: PartUsedRequest[];
  assetHours: number | null;
  unknownAssetHourReason: string | null;
  additionalReviewRequired: boolean;
}

export interface GetActivityDebriefResponse {
  scope: string;
  debriefedWith: string;
  debriefType: string;
}

export interface PartPickerRequest {
  partIds: number[];
  quantity: number;
}

export interface PartUsedRequest extends PartPickerRequest {
  surplusReason: string | null;
}

export type ActivityTime = Omit<ActivityTimeResponse, 'startTime' | 'endTime' | 'totalTime' | 'entryDate'> & {
  startTime: Date;
  endTime: Date;
  totalTime: Date;
  entryDate: Date;
};

export interface MyTimeResponse {
  activityId: string;
  activityCrmId: string;
  activityTimeCrmId: string;
  customerName: string;
  entryDate: string; // Date in string format
  startTime: string; // Date in string format
  endTime: string; // Date in string format
  type: string;
  totalTime: string; // Date in string format
}

export type MyTime = Omit<MyTimeResponse, 'startTime' | 'endTime' | 'totalTime' | 'entryDate'> & {
  startTime: Date;
  endTime: Date;
  totalTime: Date;
  entryDate: Date;
};

export interface AssetWithDetailsResponse {
  id: number;
  assetCrmId: string;
  assetName: string;
  assetManufacturer: string | null;
  crmType: string | null;
  modelNumber: string;
  serialNumber: string;
  location: string | null;
  assetGroup: string | null;
}

// How would we like to interact with this
// edge cases: may care about the user Role, may care about the number of times the user has interacted with the guide
export interface UserOnboardingInteraction {
  interactionId?: number;
  guideId: number;
  title: string;
  isActive: boolean;
  currentStep: number;
  hasCompleted: boolean;
}

export function getAssetDetailsLabel(asset: AssetWithDetailsResponse) {
  const formattedAssetDetails = asset.crmType
    ? `Type: ${asset.crmType}, Model: ${asset.modelNumber}, Serial: ${asset.serialNumber}`
    : asset.assetGroup
      ? `${asset.assetGroup} , Type: ${asset.assetManufacturer} Model: ${asset.modelNumber}, Serial: ${asset.assetName}`
      : `Type: N/A, Model: ${asset.modelNumber}, Serial: ${asset.serialNumber}`;
  //  `Asset: ${asset.assetName}`;

  return `Id: ${asset.assetCrmId}, ${formattedAssetDetails}`;
}

export interface AddInteractionRequest {
  guideId: number;
  step?: number;
  isCompleted: boolean;
}

// Contacts
export type ContactInfo = {
  id?: number | null;
  contactName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  workOrderContactId?: number | null;
};

export type GetContactsRequest = {
  customerId: number;
  workOrderId: number;
  agreementId?: number | null;
};

export type GetContactsResponse = {
  customerContacts: ContactInfo[];
  workOrderContacts: ContactInfo[];
  agreementContacts: ContactInfo[];
};

export type TimeStatField = {
  productivity: number;
  paidHours: number;
  travelHours: number;
  travelRatio: number;
  revPerHour: number;
  totalRate: number;
};
