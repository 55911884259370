import axios from 'axios';
import { PORTAL_API_URL, RMX_API_URL } from '../env';
import Cookies from 'js-cookie';

export function createStandardAxios(baseUrl?: string, token?: string) {
  return axios.create({
    baseURL: baseUrl,
    ...(token
      ? {
          headers: { Authorization: `Bearer ${token}` }
        }
      : {}),
    validateStatus: (status) => status >= 200 && status < 400
  });
}

export function getApiKey() {
  const value = Cookies.get('rmx_api_key');
  return value;
}

export const createRmxAxios = () => createStandardAxios(RMX_API_URL, getApiKey());
export const createPortalAxios = () => createStandardAxios(PORTAL_API_URL);
