import type { Options } from 'highcharts';
import { importOrShowErrorAndReturnNull } from '../../util/import-utils';

let HighChartsDarkModeTheme = {};
async function applyHighChartsTheme() {
  const Highcharts = await importOrShowErrorAndReturnNull(
    async () => (await import('./lazyHighcharts')).Highcharts,
    'Failed to load chart. Please check your internet connection and try again'
  );
  if (Highcharts === null) {
    return;
  }
  HighChartsDarkModeTheme = Highcharts.theme;
  const transformed = transformThemeToLightMode(HighChartsDarkModeTheme);
  Highcharts.theme = {
    ...transformed,
    chart: {
      ...transformed.chart,
      backgroundColor: '#ffffff'
    },
    colors: ['#5f98cf', '#434348', '#49a65e', '#f45b5b', '#708090', '#b68c51', '#397550', '#c0493d', '#4f4a7a', '#b381b3'],
    navigator: {
      ...transformed.navigator,
      series: {
        ...(transformed.navigator || {}).series,
        color: '#5f98cf',
        // @ts-ignore
        lineColor: '#5f98cf'
      }
    }
  };
  // // Apply the theme
  Highcharts.setOptions(Highcharts.theme);
}

// @ts-ignore
window.applyHighChartsTheme = applyHighChartsTheme;

async function revertHighChartsTheme() {
  const Highcharts = await importOrShowErrorAndReturnNull(
    async () => (await import('./lazyHighcharts')).Highcharts,
    'Failed to load chart. Please check your internet connection and try again'
  );
  if (Highcharts === null) {
    return;
  }
  Highcharts.theme = HighChartsDarkModeTheme;
  Highcharts.setOptions(Highcharts.theme);
}

// @ts-ignore
window.revertHighChartsTheme = revertHighChartsTheme;

function transformThemeToLightMode(darkModeTheme: Record<string, unknown>): Options {
  const dest = {};
  for (const darkModeThemeKey in darkModeTheme) {
    if (typeof darkModeTheme[darkModeThemeKey] === 'string') {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      dest[darkModeThemeKey] = getThemeColor(darkModeThemeKey, dest[darkModeThemeKey]);
    } else if (darkModeTheme[darkModeThemeKey] instanceof Object) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      dest[darkModeThemeKey] = transformThemeToLightMode(darkModeTheme[darkModeThemeKey]);
    } else if (Array.isArray(darkModeTheme[darkModeThemeKey])) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
      dest[darkModeThemeKey] = darkModeTheme[darkModeThemeKey].map((item) => {
        if (typeof item === 'string') {
          return '#000000';
        } else if (item instanceof Object) {
          return transformThemeToLightMode(item as Record<string, unknown>);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return item;
      });
    } else {
      // @ts-ignore
      dest[darkModeThemeKey] = darkModeTheme[darkModeThemeKey];
    }
  }
  return dest;
}

function getThemeColor<T>(key: string, value: T) {
  if (key.toLowerCase().indexOf('background') !== -1) {
    return '#ffffff';
  }
  if (key.toLowerCase().indexOf('color') !== -1) {
    return '#000000';
  }
  return value;
}
