import { openBypassModal } from '../../utils/BypassModal/BypassModal';
import { getLegacyPortalApi } from '../../legacy-portal-api/legacy-api';
/* eslint-disable */
declare global {
  interface Window {
    getSelectedRow: () => ActiveAlertRow;
    openAlertDetailsModal: () => void;
  }
}
export interface ActiveAlertRow {
  Company: string;
  Site: string;
  System: string;
  Asset: string;
  Model: string;
  SN: string;
  AlertStatus: string;
  Alert: string;
  AlertDescription: string;
  AlertResponse: string;
  Start: string;
  SiteID: number;
  CompanyID: number;
  CompanyGUID: string | null;
  SiteGUID: string | null;
  InstallationID: number;
  InstallationName: string;
  AssetId: number | null;
  SystemProcessId: number | null;
  Color: string;
  Severity: number;
  Subject: string;
}
// @ts-ignore
window.initializeActiveAlertsMenu = async function ActiveAlertsModalMenu(row: ActiveAlertRow, type, modalTitle) {
  const assetName = modalTitle;
  const legacyApi = getLegacyPortalApi();
  const assetDetails = await legacyApi.getAssetDetails(row.AssetId!);
  const menuConfig = {
    details: () => {
      window.openAlertDetailsModal();
    },

    open_site: () => {
      window.location.href = `${window.origin}?p=InstallationPage&pid=${row.SiteGUID}`;
    },

    getLabel: () => assetName,
    bypass: () => {
      // @ts-ignore
      openBypassModal({
        assetSerial: assetDetails.AssetSerial,
        assetModel: assetDetails.AssetModel,
        id: row.AssetId!,
        siteName: row.Site,
        type
      });
    }
  };

  window.OpenMenuModal(modalTitle, menuConfig);
};
