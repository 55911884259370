import { Button, Card, CardBody } from 'react-bootstrap';
import React from 'react';
import s from './RmxServiceUploads.module.scss';
import { Icon } from '../../status-indicator/Icon/Icon';
import { faCheck, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRmxServiceApi } from '../api';
import { type UseQueryResult, useMutation } from '@tanstack/react-query';
import type { IActivityUpload, RequiredActivityUpload } from '../api/portal-api';
import { useRmxServiceModalContext } from '../RmxServiceTabs/RmxServiceModalContext';
import { Spinner } from '../../shared-compat/DataTable/DataTable';
import { LoadingButton } from '../../../components/util/widgets/LoadingButton/LoadingButton';
import { RmxServiceUploadModal, type RmxServiceUploadModalParam } from '../RmxServiceUploadModal/RmxServiceUploadModal';
import { useModal } from '../../hooks/useModal';

interface Props {
  activityId: string;
  existingActivityUploads: IActivityUpload[];
  requiredUploadsQuery: UseQueryResult<RequiredActivityUpload[], Error>;
  enabled: boolean;
}

export function RmxServiceUploads({ enabled, activityId, existingActivityUploads, requiredUploadsQuery }: Props) {
  const api = useRmxServiceApi();
  const { refetchUploads } = useRmxServiceModalContext();
  const uploadModal = useModal<RmxServiceUploadModalParam>();

  const { data: requiredUploads, isPending: pendingRequiredUploads } = requiredUploadsQuery;

  const { mutateAsync: deleteActivityUpload, isPending: pendingDeleteActivityUpload } = useMutation({
    mutationFn: async (id: string) => {
      await api.deleteActivityUpload(id);
      await refetchUploads.refetch();
    }
  });
  // determine whether there are existing uploads that are not in the required uploads
  const existingAdditionalUploads = existingActivityUploads.filter(
    (existingUpload) => requiredUploads?.findIndex((r) => r.title === existingUpload.title) === -1
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '415px',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      <Card style={{ width: '100%' }}>
        <Card.Header style={{ textAlign: 'center' }}>Uploads</Card.Header>
        <CardBody className={s['main-upload-card-body']}>
          <Card style={{ width: '100%' }}>
            <Card.Header>
              <div style={{ minHeight: '20px' }}>Required Uploads</div>
            </Card.Header>
            <Card.Body
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflowY: 'auto'
              }}
            >
              {pendingRequiredUploads ? (
                <Spinner />
              ) : requiredUploads && requiredUploads.length > 0 ? (
                requiredUploads.map((upload, index) => {
                  const existingUpload = existingActivityUploads?.find((value: { title: string }) => value.title === upload.title);
                  return (
                    <div className={s['card']} key={index}>
                      <Button
                        disabled={!enabled}
                        style={{ display: 'block', width: '100%' }}
                        variant={'secondary'}
                        onClick={async () => {
                          await uploadModal.open({
                            id: existingUpload?.id,
                            activityId: activityId,
                            title: upload.title,
                            noUploadReason: existingUpload?.noUploadReason ? existingUpload?.noUploadReason : undefined,
                            fileNames: existingUpload?.fileNames ?? [],
                            disableTitle: true
                          });
                        }}
                      >
                        {upload.title}
                        {existingUpload && <Icon size='lg' className={s['success-icon']} icon={faCheck} />}
                      </Button>
                    </div>
                  );
                })
              ) : (
                <div className={s['card']}>No Required Uploads For This Activity</div>
              )}
            </Card.Body>
          </Card>

          <Card style={{ width: '100%' }}>
            <Card.Header>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                Additional Uploads
                <Button
                  disabled={!enabled}
                  size='sm'
                  onClick={() => uploadModal.open({ activityId, disableTitle: false, title: '' })}
                  style={{
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0',
                    backgroundColor: 'transparent',
                    borderColor: 'transparent'
                  }}
                  variant='outline-secondary'
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                </Button>
              </div>
            </Card.Header>

            <Card.Body style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {existingAdditionalUploads.length <= 0 ? (
                <div className={s['card']}>No Uploads Added</div>
              ) : (
                existingAdditionalUploads.map((existingUpload, index) => {
                  return (
                    <div key={index} style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                      <div style={{ position: 'relative' }}>
                        <Button
                          disabled={!enabled}
                          onClick={async () => await uploadModal.open({ ...existingUpload, disableTitle: true, activityId })}
                          style={{ display: 'block', width: '100%' }}
                          variant={'secondary'}
                        >
                          {existingUpload.title}
                          {<Icon className={s['success-icon']} icon={faCheck} />}
                        </Button>
                      </div>
                      <LoadingButton
                        variant='outline-secondary'
                        disabled={!enabled}
                        loading={pendingDeleteActivityUpload || refetchUploads.isRefetching}
                        onClick={async () => {
                          await deleteActivityUpload(existingUpload.id as string);
                        }}
                        label={<FontAwesomeIcon icon={faTrash} />}
                      />
                    </div>
                  );
                })
              )}
            </Card.Body>
          </Card>
        </CardBody>
      </Card>
      <RmxServiceUploadModal
        state={uploadModal}
        onExit={async () => {
          await refetchUploads.refetch();
        }}
        enabled={enabled}
      />
    </div>
  );
}
